import React from "react";
import PublicRoute from "./components/PublicRoute";
import { Switch, Route } from "react-router-dom";

import MDHomePage from "./pages/Home";
import MDPrivacyPolicy from "./pages/PrivacyPolicy";

/**
 * @typedef {[{ path: string, component: JSX }]} publicRoutes
 * The public routes of this application.
 */

const publicRoutes = [
  { path: "/", component: MDHomePage },
  { path: "/legal/privacy_policy", component: MDPrivacyPolicy }
];

class MendullaWeb extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <React.Fragment>
        <Switch>
          {publicRoutes.map(route => (
            <PublicRoute
              exact
              path={route.path}
              component={route.component}
              key={route.path}
            />
          ))}

          <Route redirect={this.redirect} component={MDHomePage} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default MendullaWeb;
