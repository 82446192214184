import React, { Suspense } from "react";
import PropTypes from "prop-types";
import MDFooterSection from "../components/FooterSection";
import MDPrivacyPolicySection from "../components/privacy_policy/PrivacyPolicySection";
import "../i18n";

const MDPrivacyPolicy = props => (
  <>
    <Suspense fallback={<div></div>}>
      {/* <HCHeader/> */}
      <div className="privacyContent">
        <MDPrivacyPolicySection />
        <MDFooterSection />
      </div>
      {/* <HCFooter/> */}
    </Suspense>
  </>
);

MDPrivacyPolicy.propTypes = {
  t: PropTypes.func
};

export default MDPrivacyPolicy;
