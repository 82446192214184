import React from "react";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TextLoop from "react-text-loop";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MDDownloadButton } from "../Buttons";
import MDLogo from "../Logo";

const MDHomeSection = props => {
  return (
    <>
      <section id="home" className="homeSection">
        <div className="content">
          <Row>
            <Col lg={8} md={7} sm={6}>
              <MDLogo />
              <div className="description">
                <Trans i18nKey="sliderTitle">
                  Challenge and Compete with <br />
                  your Friends & Contacts in <br />
                  Realtime on
                </Trans>
                <span className="challengeType">
                  <TextLoop>
                    <span className="quiz">{props.t("quiz")}</span>
                    <span className="spellings">{props.t("spelling")}</span>
                  </TextLoop>
                </span>
              </div>

              <div className="downloadContainer ml-auto">
                <div className="float-left">
                  <MDDownloadButton
                    title={props.t("download")}
                    subtitle={props.t("playStore")}
                    iconURL="/images/playStore.svg"
                  />
                </div>
                <div className="float-left buttonPaddingLeft">
                  <MDDownloadButton
                    title={props.t("download")}
                    subtitle={props.t("appStore")}
                    iconURL="/images/appStore.svg"
                  />
                </div>
              </div>
            </Col>
            <Col lg={4} md={5} sm={6}>
              <div className="phone"></div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

MDHomeSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(MDHomeSection);
