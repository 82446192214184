import React, { Suspense } from "react";
import MDHomeSection from "../components/home/HomeSection";
import MDAboutSection from "../components/home/AboutSection";
import MDFeatureSection from "../components/home/FeatureSection";
import MDScreenshotSection from "../components/home/ScreenshotSection";
import MDDownloadSection from "../components/home/DownloadSection";
import MDFooterSection from "../components/FooterSection";
import MDMenu from "../components/Menu";
import "../i18n";

const MDHomePage = () => (
  <>
    <Suspense fallback={<div></div>}>
      {/* <HCHeader/> */}
      <div className="homeContent">
        <MDMenu />
        <MDHomeSection />
        <MDAboutSection />
        <MDFeatureSection />
        <MDScreenshotSection />
        <MDDownloadSection />
        <MDFooterSection />
      </div>
      {/* <HCFooter/> */}
    </Suspense>
  </>
);

export default MDHomePage;
