import React from "react";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MDParagraphBox from "../ParagraphBox";
import MDLogo from "../Logo";

const MDPrivacyPolicySection = props => (
  <>
    <MDLogo />

    <div className="content">
      <div className="pageTitle">{props.t("privacyPolicy")}</div>
      <MDParagraphBox>{props.t("privacyPolicyPG1")}</MDParagraphBox>
      <MDParagraphBox>
        <Trans i18nKey="privacyPolicyPG2">
          website at
          <a
            href="https://mendulla.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ website: props.t("websiteURL") }}
          </a>
          or through our mobile application on Terms and Conditions tab on
          profile page. By accepting our Privacy Policy and Terms of Use Found
          here:
          <a
            href="https://mendulla.com/legal/privacy_policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ privacyPolicyLink: props.t("privacyURL") }}
          </a>
          and
          <a
            href="https://mendulla.com/legal/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ termsLink: props.t("termsURL") }}
          </a>
          , you consent to our collection storage, use and disclosure of your
          personal information as described in this Privacy Policy.
        </Trans>
      </MDParagraphBox>
      <MDParagraphBox title={props.t("infoWeCollect")}>
        {props.t("privacyPolicyPG3")}
      </MDParagraphBox>
      <MDParagraphBox
        title={props.t("howWeUseInfo")}
        subtitle={props.t("personalInfo")}
      >
        {props.t("privacyPolicyPG4")}
      </MDParagraphBox>
      <MDParagraphBox subtitle={props.t("nonPersonalinfo")}>
        {props.t("privacyPolicyPG5")}
      </MDParagraphBox>
      <MDParagraphBox title={props.t("howWeProtectInfo")}>
        {props.t("privacyPolicyPG6")}
      </MDParagraphBox>
      <MDParagraphBox title={props.t("yourRightOnInfoUse")}>
        {props.t("privacyPolicyPG7")}
      </MDParagraphBox>
      <MDParagraphBox title={props.t("linkToOtherPlatforms")}>
        {props.t("privacyPolicyPG8")}
      </MDParagraphBox>
      <MDParagraphBox title={props.t("changesToOurPrivacyPolicy")}>
        {props.t("privacyPolicyPG9")}
      </MDParagraphBox>
      <MDParagraphBox title={props.t("contactUs")}>
        {props.t("privacyPolicyPG10")}
      </MDParagraphBox>

      <MDParagraphBox>{props.t("privacyPolicyPG11")}</MDParagraphBox>
    </div>
  </>
);

MDPrivacyPolicySection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(MDPrivacyPolicySection);
