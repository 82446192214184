import React from "react";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MDFeatureBox from "../FeatureBox";

const MDAboutSection = props => {
  return (
    <>
      <section id="features" className="featureSection">
        <div className="content">
          <div className="featureMissionStatement">
            <Trans i18nKey="featureMissionStatement">
              All Features Have One Mission; <br />
              To Boost Your Knowledge and Cashflow
            </Trans>
          </div>
          <div className="hrContainer">
            <hr />
          </div>
          <Row className="featureBoxRow">
            <Col>
              {/* HIDE WHEN MOBILE DEVICE */}
              <div className="hideOnMobile">
                <MDFeatureBox
                  title={props.t("competition")}
                  description={props.t("competitionFeatureDesc")}
                  iconURL="/icons/trophy.svg"
                />
                <MDFeatureBox
                  title={props.t("challenge")}
                  description={props.t("challengeFeatureDesc")}
                  iconURL="/icons/globe.svg"
                />
              </div>
              {/* SHOW WHEN MOBILE DEVICE */}
              <div className="showOnMobile">
                <MDFeatureBox
                  title={props.t("competition")}
                  description={props.t("competitionFeatureDesc")}
                  iconURL="/icons/trophy.svg"
                  isPositionRight={true}
                />
                <MDFeatureBox
                  title={props.t("challenge")}
                  description={props.t("challengeFeatureDesc")}
                  iconURL="/icons/globe.svg"
                  isPositionRight={true}
                />
              </div>
            </Col>
            <Col lg={3} md={4}>
              <div className="phoneBox"></div>
            </Col>
            <Col>
              <div>
                <MDFeatureBox
                  title={props.t("wallet")}
                  description={props.t("walletFeatureDesc")}
                  iconURL="/icons/wallet.svg"
                  isPositionRight={true}
                />
                <MDFeatureBox
                  title={props.t("scholars")}
                  description={props.t("scholarsFeatureDesc")}
                  iconURL="/icons/people.svg"
                  isPositionRight={true}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

MDAboutSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(MDAboutSection);
