import React from "react";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MDInfoBox from "../InfoBox";

const infoBoxData = [
  {
    title: "quiz",
    text: "quizInfo",
    icon: "/icons/numbered.svg"
  },
  {
    title: "spelling",
    text: "spellingInfo",
    icon: "/icons/spell-check.svg"
  }
];

const MDAboutSection = props => {
  var infoBoxes = infoBoxData.map((info, index) => (
    <Col key={`infoBox${index}`} md={4} lg={3}>
      <MDInfoBox
        title={props.t(info.title)}
        text={props.t(info.text)}
        linkText={props.t("seeMoreInfo")}
        iconURL={info.icon}
      />
    </Col>
  ));

  return (
    <>
      <section id="about" className="aboutSection">
        <div className="content">
          <div className="missionStatement">
            <Trans i18nKey="missionStatement">
              We Are Putting A Premuim on Knowledge <br />
              To Reduce Ignorance
            </Trans>
          </div>
          <div className="hrContainer">
            <hr />
          </div>
          <Row>
            <Col md={{ span: 12, offset: 2 }} lg={{ span: 12, offset: 3 }}>
              <Row className="infoBoxRow">{infoBoxes}</Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

MDAboutSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(MDAboutSection);
