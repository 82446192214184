import React from "react";
import PropTypes from "prop-types";
import "./styles/Buttons.css";

export const MDDownloadButton = props => {
  return (
    <>
      <div className="downloadButton">
        <div className="textContainer">
          <span className="download">{props.title}</span>
          <div className="storeText">{props.subtitle}</div>
        </div>
        <div
          className="storeIcon"
          style={{ backgroundImage: `url('${props.iconURL}')` }}
        ></div>
      </div>
    </>
  );
};

MDDownloadButton.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  iconURL: PropTypes.string
};

export const MDMenuButton = props => {
  return (
    <>
      <div className="menuButtonContainer">
        <div
          className="icon"
          style={{ backgroundImage: `url('${props.iconURL}')` }}
        ></div>
      </div>
    </>
  );
};

MDMenuButton.propTypes = {
  iconURL: PropTypes.string
};
