import React from "react";
import PropTypes from "prop-types";
import "./styles/ParagraphBox.css";

const MDParagraphBox = props => {
  return (
    <>
      <div className="paragraphBox">
        {props.title ? (
          <div className="paragraphTitle">{props.title}</div>
        ) : (
          <></>
        )}
        {props.subtitle ? (
          <div className="paragraphSubTitle">{props.subtitle}</div>
        ) : (
          <></>
        )}
        <div className="paragraphText">{props.children}</div>
      </div>
    </>
  );
};

MDParagraphBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any
};

export default MDParagraphBox;
