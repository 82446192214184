import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MDDownloadButton } from "../Buttons";

const MDDownloadSection = props => {
  return (
    <>
      <section id="download" className="downloadSection">
        <div className="content">
          <Row>
            <Col md={6}>
              <div className="captionBox">
                <div className="title">{props.t("downloadSectionTitle")}</div>
                <div className="caption">
                  {props.t("downloadSectionCaption")}
                </div>

                <div className="downloadContainer">
                  <MDDownloadButton
                    title={props.t("download")}
                    subtitle={props.t("playStore")}
                    iconURL="/images/playStore.svg"
                  />
                  <MDDownloadButton
                    title={props.t("download")}
                    subtitle={props.t("appStore")}
                    iconURL="/images/appStore.svg"
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="phone"></div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

MDDownloadSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(MDDownloadSection);
