import React from "react";
import { Trans } from "react-i18next";
import "./styles/Logo.css";

const MDLogo = () => {
  return (
    <>
      <a href="/" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
        <div className="appLogoContainer">
          <div
            className="logo"
            style={{ backgroundImage: `url('/images/logo.svg')` }}
          ></div>
          <div className="textContainer">
            <div className="appName">
              <span>Mendulla</span>
            </div>
            <div className="slogan">
              <Trans i18nKey="slogan" />
            </div>
          </div>
        </div>
      </a>
    </>
  );
};

export default MDLogo;
